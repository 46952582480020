@font-face {
  font-family: "StabilGrotesk";
  src: url("../fonts/StabilGrotesk/StabilGrotesk-Light.otf");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "StabilGrotesk";
  src: url("../fonts/StabilGrotesk/StabilGrotesk-Medium.otf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "KlarheitGrotesk";
  src: url("../fonts/KlarheitGrotesk/KlarheitGrotesk-Medium.ttf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "KlarheitGrotesk";
  src: url("../fonts/KlarheitGrotesk/KlarheitGrotesk-Semibold.ttf");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "KlarheitGrotesk";
  src: url("../fonts/KlarheitGrotesk/KlarheitGrotesk-Bold.ttf");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "KlarheitGrotesk";
  src: url("../fonts/KlarheitGrotesk/KlarheitGrotesk-Book.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
