@import "./assets/styles/typography.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  color: #454545;
  font-family: "StabilGrotesk", georgia, serif;
  font-weight: normal;
  word-wrap: break-word;
  font-kerning: normal;
  position: relative;
  height: 100vh;
  overflow-y: hidden;
}
#root {
  height: 100%;
  overflow-y: scroll;
}

.grotesk {
  font-family: "KlarheitGrotesk", georgia, serif;
}

h1 {
  @apply text-black font-medium text-2xl md:text-[28px];
}

h2 {
  @apply text-black font-normal text-xl leading-6;
}
h3 {
  @apply text-black font-semibold text-lg leading-6;
}

.auth-bg {
  background-image: url("./assets//images/authIllustrations.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 99%;
}

.auth-card-shadow {
  box-shadow: 0px 2px 48px 12px rgba(234, 234, 234, 0.5);
}

.glass-effect {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.container-start {
  @apply flex items-center justify-start;
}
.container-end {
  @apply flex items-center justify-end;
}
.container-center {
  @apply flex items-center justify-center;
}
.container-between {
  @apply flex items-center justify-between;
}

.bordered-list li {
  @apply border-b border-b-gray-border py-3;
}
.bordered-list li:first-child {
  @apply pt-0;
}
.bordered-list li:last-child {
  @apply border-b-0 py-3;
}
/* PAGINATION */
.lr-pagination {
  @apply flex space-x-1 text-xs font-semibold grotesk items-center justify-start;
}
.lr-pagination-item {
  @apply border border-[#ECECEC] rounded-[4px] h-6 px-2 text-[#454545] py-1 leading-4;
}

.lr-pagination-item_active {
  @apply bg-[#454545] text-white h-6 px-2 py-1 leading-4;
}
.lr-pagination-previous,
.lr-pagination-next {
  @apply bg-[#D1D1D1] text-white rounded-[4px] h-6 px-1 flex items-center justify-center pt-[5px];
}

/* TABLE */
.table-head-row p:last-child {
  @apply text-right;
}
.lr-table-row div:last-child > div {
  text-align: right !important;
}
.lr-table-row {
  box-shadow: none;
}

/* DATE PICKER */
.rdp-caption_label {
  font-size: 16px !important;
}
.rdp-nav_button {
  height: 24px !important;
  width: 24px !important;
}
.rdp-nav {
  @apply space-x-6 !important;
}
.rdp-head_cell {
  font-size: 12px !important;
  @apply text-black-20;
}
.rdp-cell {
  font-size: 12px !important;
}

.rdp-caption {
  @apply mb-3;
}
.rdp-weeknumber,
.rdp-day {
  width: 36px !important;
  height: 36px !important;
}

@media screen and (min-width: 1024px) {
  .lr-table-row {
    box-shadow: 0px 0px 8px 0px #eaeaea8c;
  }
}

.hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.react-tel-input .form-control {
  font-family: "KlarheitGrotesk";
  font-size: 12px !important;
  font-weight: 700 !important;
  background: #ffffff !important;
  border: 0px !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #cacaca !important;
  width: 100% !important;
  outline: none !important;
  transition: box-shadow ease 0.25s, border-color ease 0.25s;
  color: black !important;
  outline: 2px solid transparent !important;
  outline-offset: 2px;
  padding: 2px 14px 6px 60px !important;
}
